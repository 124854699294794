import './i18n';
import React from 'react';
import Auth from './components/Auth';

const App = () => {
  return (
    <Auth/>
  );
};

export default App;
