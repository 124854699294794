const configLocal = {
  VH_ORDER: "http://localhost:8185",
  PAYMENT_SUCCESS_URL: "https://localhost:3000/success",
  PAYMENT_CANCEL_URL: "https://localhost:3000/order",
  PAYMENT_ERROR_URL: "https://localhost:3000/error"
}

const configStaging = {
  VH_ORDER: "https://vh.2serv.eu/api/pay",
  PAYMENT_SUCCESS_URL: "https://vh-04.2serv.eu/success",
  PAYMENT_CANCEL_URL: "https://vh-04.2serv.eu/order",
  PAYMENT_ERROR_URL: "https://vh-04.2serv.eu/error"
}

const configProd = {
  VH_ORDER: "https://api.vh.kli.one/pay",
  PAYMENT_SUCCESS_URL: "https://payment.vh.kli.one/success",
  PAYMENT_CANCEL_URL: "https://payment.vh.kli.one/order",
  PAYMENT_ERROR_URL: "https://payment.vh.kli.one/error"
}

if (process.env.REACT_APP_LOCAL === "true") { 
  module.exports = configLocal; 
}

else {
  if (process.env.REACT_APP_STAGING === "true") { 
    module.exports = configStaging; 
  } else {
    module.exports = configProd;
  }
}
